import {get, patch, post} from "./index.js";

const NAMESPACE = "/backoffice/landlord"

const list = async (paginationKey, searchKey, filters, pageSize) => {
    return await get(NAMESPACE + "/list", {paginationKey, searchKey, filters, pageSize})
}

const getLandlordById = async (landlord_id)=>{
    return await get(`${NAMESPACE}/${landlord_id}`);

}
const getLandlordVisits = async (landlord_id)=>{
    return await get(`${NAMESPACE}/${landlord_id}/visits`);

}
const getLandlordProperties = async (landlord_id)=>{
    return await get(`${NAMESPACE}/${landlord_id}/properties`);
}
const getElasticListings = async (landlord_id, page, searchKey, filters, pageSize)=>{
    return await get(`${NAMESPACE}/${landlord_id}/listings`, {page, searchKey, filters, pageSize});
}
const advancedUpdateListings = async (landlord_id, changes) => {
    return await post(`${NAMESPACE}/${landlord_id}/updateListings`, {changes});
}
const getLandlordReservations = async (landlord_id, size)=>{
    return await get(`${NAMESPACE}/${landlord_id}/reservations`, {size});
}

const getConfirmedReservations = async (landlord_id) => {
    return await get(`${NAMESPACE}/${landlord_id}/confirmedReservations`);
}

const editLandlord = async (landlord_id, accountDetails)=>{
    return await post(`${NAMESPACE}/${landlord_id}/saveAccountDetails`, {accountDetails});
}

const validateIban = async (landlord_id, bankInfoId, iban)=>{
    return await post(`${NAMESPACE}/${landlord_id}/validateIban`, {bankInfoId, iban});
}

const verifyPhoto = async (landlord_id)=>{
    return await post(`${NAMESPACE}/${landlord_id}/verifyPhoto`);
}

const deleteLandlord = async (landlord_id) => {
    return await post(`${NAMESPACE}/${landlord_id}/delete`);
}

const activateLandlord = async (landlord_id) => {
    return await post(`${NAMESPACE}/${landlord_id}/activate`);
}

const getSheetsIntegration = async (landlord_id) => {
    return (await post(`${NAMESPACE}/${landlord_id}/sheetsIntegration`)).spreadsheetId;
}

const clearSheetsIntegration = async (landlord_id) => {
    return await post(`${NAMESPACE}/${landlord_id}/clearSheetsIntegration`);
}

const removePenalty = async (landlord_id) => {
    try {
        return await post(`${NAMESPACE}/${landlord_id}/removePenalty`);
    } catch (e) {
        return {error: e};
    }
}

export const landlordService = {
    list,
    getLandlordById,
    getLandlordVisits,
    getLandlordProperties,
    getElasticListings,
    advancedUpdateListings,
    getLandlordReservations,
    getConfirmedReservations,
    editLandlord,
    validateIban,
    verifyPhoto,
    deleteLandlord,
    activateLandlord,
    getSheetsIntegration,
    clearSheetsIntegration,
    removePenalty
}
