import React from 'react'
import {Link} from "react-router-dom";
import FiltersSection from "./FiltersSection";



const PageWrapper = ({title, breadcrumbs, inline, noCard, children, setFilters, filters, hideTitle = false}) => {

    return ( inline ? <>
            {children}
        </> :
        <div>
            <div className="page-header">
                {!hideTitle && <h3 className="page-title"> {title} </h3>}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumbs?.map((breadcrumb,index) => (
                            <li key={breadcrumb.title+index} className={"text-capitalize breadcrumb-item"+((index===breadcrumbs.length-1)?' active':'')}>
                                {breadcrumb.url ?
                                    <Link to={"/"+breadcrumb.url}>{breadcrumb.title}</Link> :
                                    <span>{breadcrumb.title}</span>
                                }
                            </li>)
                        )}
                    </ol>
                </nav>
            </div>
            {filters && <FiltersSection type={title?.replaceAll(" ", "_").toLowerCase()} filters={filters} setFilters={setFilters}/>}
            {noCard ? children : <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div  className="card-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default PageWrapper;
