import React, {useEffect, useState} from 'react';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import {dataService} from "../../services/dataService";
import {landlordService} from "../../services/landlordService";
import moment from 'moment';



const DateRanger = ({label, startFilter, endFilter, filters, setFilters, yearPicker}) => {
    return(
        <div className={"dates-input"}>
            <label>{label}</label>
            <div className="daterange-picker">
                <DatePicker
                    selected={filters[startFilter] && moment(filters[startFilter]).toDate()}
                    onChange={date => setFilters(startFilter, date)}
                    className="form-control"
                    dateFormat={"dd/MM/yyyy"}
                    selectsStart
                    showYearPicker={yearPicker}
                    startDate={filters[startFilter] && moment(filters[startFilter]).toDate()}
                    endDate={filters[endFilter] && moment(filters[endFilter]).toDate()}
                    isClearable
                    placeholderText={"Start"}
                />
                <span className="range-seperator">to</span>
                <DatePicker
                    selected={filters[endFilter] && moment(filters[endFilter]).toDate()}
                    onChange={date => setFilters(endFilter, date)}
                    className="form-control"
                    dateFormat={"dd/MM/yyyy"}
                    selectsEnd
                    showYearPicker={yearPicker}
                    startDate={filters[startFilter] && moment(filters[startFilter]).toDate()}
                    endDate={filters[endFilter] && moment(filters[endFilter]).toDate()}
                    minDate={filters[startFilter] && moment(filters[startFilter]).toDate()}
                    isClearable
                    placeholderText={"End"}
                />
            </div>
        </div>
    )
}


const OrderBy = ({label, filter, setFilters, filters}) => {

    return(
        <div className={"clickable ml-3 d-flex align-items-center"}
             onClick={() => setFilters(filter, (filters[filter] === "desc" ? "asc" : "desc"))}>
            {label}
            <i className={"ml-2 mdi mdi-sort" + (filters[filter] ? "-" + filters[filter]+ "ending" : "")} />
        </div>
    )
}

const CheckBox = ({label, filter, setFilters, filters}) => {

    return(
        <div className={"d-flex flex-column align-items-center ml-3"}>
            <div>{label}</div>
            <label className={"switch"} htmlFor={filter} style={{marginBottom: "unset"}}>
                <input type={"checkbox"} className={"ml-2"} id={filter} name={filter}
                       checked={filters[filter]}
                       onChange={() => setFilters(filter, !filters[filter])} />
                <span className="slider round" />
            </label>
        </div>
    )
}

const FiltersSection = ({type, filters, setFilters}) => {

    const [showFilters, setShowFilters] = useState(true)
    const [regions, setRegions] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [landlords, setLandlords] = useState([]);
    const [integrationPlatforms, setIntegrationPlatforms] = useState([]);

    const changeFilters = (filter, value) => {
        let newFilters = {...filters, [filter]: value};
        if(filter === "emailName"){
            ["bookingType", "cancellationType", "cancellationDate", "priceChanged", "cancellationDate", "moveInDate", "moveOutDate", "tag",
                "cancellationMotive", "rejectedMotive", "motive", "rejectionType", "notificationType", "cancellationType", "reminderType",
                "suggestionType", "type", "emailTag"].forEach((fil) => {
                    delete newFilters[fil];
            })
            let filters = emailFilterByName.find((fil) => fil.name === value)?.filters;
            if (filters)
            Object.entries(filters).forEach(([key, value]) => {
                newFilters[key] = value;
            })
        }
        setFilters(newFilters);
        localStorage.setItem(type + "_filters", JSON.stringify(newFilters));
    }

    let reservationStatusOptions = [
        {value: "request", label: "Open Request"},
        {value: "payment", label: "Waiting Payment"},
        {value: "waiting_tenant", label: "Waiting Tenant Acceptance"},
        {value: "confirmed", label: "Confirmed"},
        {value: "expired", label: "Exp - All"},
        {value: "expired_payment", label: "Exp - No Payment"}, {value: "expired_reply", label: "Exp - No Reply"},
        {value: "rejected", label: "Rejected - All"},
        {value: "rejected_landlord", label: "Rejected by Landlord"},
        {value: "rejected_other_tenant", label: "Rejected - Confirmed other tenant"},
        {value: "rejected_other_listing", label: "Rejected - Confirmed other listing"},
        {value: "rejected_other_suggestion", label: "Rejected - Other Suggestion"},
        {value: "request_cancellation_tenant", label: "Cancellation Request by Tenant"},
        {value: "request_cancellation_landlord", label: "Cancellation Request by Landlord"},
        {value: "cancelled_landlord_penalty", label: "Cancelled by Landlord - With Penalty"},
        {value: "cancelled_landlord_no_penalty", label: "Cancelled by Landlord - No Penalty"},
        {value: "cancelled_tenant_refund", label: "Cancelled by Tenant - With Refund - No Landlord Penalty"},
        {value: "cancelled_tenant_refund_penalty", label: "Cancelled by Tenant - With Refund - With Landlord Penalty"},
        {value: "cancelled_tenant_no_refund", label: "Cancelled by Tenant - No Refund - No Landlord Penalty"},
        {value: "cancelled_tenant_no_refund_penalty", label: "Cancelled by Tenant - No Refund - With Landlord Penalty"},
        {value: "cancelled_inlife", label: "Cancelled by Inlife"},
    ]

    let visitStatusOptions = ["confirmed", "expired", "request", "pending", "rejected", "completed"].map(t => ({value: t, label: t.charAt(0).toUpperCase() + t.slice(1)}));
    let accommodationTypes = ["rooms", "apartment", "residence"].map(t => ({value: t, label: t.charAt(0).toUpperCase() + t.slice(1)}))
    let userOccupations = ["student", "worker", "internship", "other"].map(t => ({value: t, label: t.charAt(0).toUpperCase() + t.slice(1)}))
    let reservationTypes = ["booking", "instant booking"].map(t => ({value: t, label: t === "booking" ? "Request" : "Instant"}));
    let visitTypes = ["onsite", "livecall"].map(t => ({value: t, label: t.charAt(0).toUpperCase() + t.slice(1)}));
    let landlordLevels = ["topLandlord", "risingStar"].map(t => ({value: t, label: t === "topLandlord" ? "Top Landlord" : "Rising Star"}));
    let houseStates = ["published", "not_finished", "disabled"].map(s => ({value: s, label: s === "published" ? "Published" : (s === "disabled" ? "Disabled" : "In Progress")}));
    let languagesOptions = ["en", "it", "es", "pt"].map(t => ({value: t, label: t.toUpperCase()}));
    let emailTypes = ["landlord", "student", "management", "ambassador"].map(t => ({value: t, label: t.charAt(0).toUpperCase() + t.slice(1)}));
    let emailTag = ["landlord_account_validation", "landlord_booking_accepted", "landlord_booking_cancellation", "landlord_booking_cancellation_request", "landlord_booking_cancellation_response", "landlord_booking_rejected", "landlord_booking_reminder", "landlord_booking_request_sent", "landlord_booking_suggestion_accepted", "landlord_booking_suggestion_rejected", "landlord_booking_suggestion_sent", "landlord_offer_sent", "landlord_potential_tenants_weekly",
        "landlord_property_reminder", "landlord_recover_password", "landlord_status", "landlord_visit_1_hour", "landlord_visit_cancelled", "landlord_visit_confirmed", "landlord_visit_request", "landlord_visit_suggestion_sent", "landlord_welcome", "management_notification", "management_property_validation", "student_booking_accepted", "student_booking_ad_suggested", "student_booking_cancellation", "student_booking_cancellation_request",
        "student_booking_cancellation_response", "student_booking_dates_suggested", "student_booking_rejected", "student_booking_request_accepted", "student_booking_request_sent", "student_booking_suggestion_accepted", "student_booking_suggestion_expired", "student_booking_suggestion_rejected", "student_promo_code", "student_promo_reminder", "student_recommendation", "student_visit_1_hour", "student_visit_after", "student_visit_cancelled",
        "student_visit_confirmed", "student_visit_rejected", "student_visit_request_sent", "student_visit_suggestion", "student_visit_tomorrow"].map(t => ({value: t, label: t}));

    const emailFilterByName = [
        {name: "1 day after account creation", filters: {tag: "student_promo_reminder", reminderType: "1day"}}, {name: "2 days after account creation", filters: {tag: "student_promo_reminder", reminderType: "2day"}}, {name: "5 days after account creation", filters: {tag: "student_promo_reminder", reminderType: "5day"}}, {name: "Listing started but not finished", filters: {tag: "landlord_property_reminder", type: "not_published"}},
        {name: "Listing validated and published", filters: {tag: "landlord_property_reminder", reminderType: "validation_completed"}}, {name: "listing validation request", filters: {tag: "landlord_property_reminder", type: "validation_request"}}, {name: "After account validation", filters: {tag: "landlord_welcome"}}, {name: "After visit - Please book this place", filters: {tag: "student_visit_after"}}, {name: "Booking request - Payment not completed", filters: {tag: "student_booking_rejected", motive: "tenant_timeout"}}, {name: "Booking request accepted - Please pay", filters: {tag: "student_booking_request_accepted"}}, {name: "Booking request accepted - Reminder to make payment (Not Working)", filters: {}, otherInfo: "Sent when 8 hours and 1 hour left to complete payment"}, {name: "Booking request confirmation - student", filters: {tag: "student_booking_accepted"}}, {name: "Booking request confirmation - landlord", filters: {tag: "landlord_booking_accepted"}}, {name: "Booking request confirmation - Reminder landlord details (not working)", filters: {}, otherInfo: "sent after Booking request confirmation"}, {name: "Booking request expired", filters: {tag: "student_booking_rejected", motive: "landlord_timeout"}}, {name: "Booking request expired - 24 hours", filters: {tag: "landlord_booking_rejected", rejectedMotive: "landlord_timeout"}}, {name: "Booking request expired - payment not completed", filters: {tag: "landlord_booking_rejected", rejectedMotive: "tenant_timeout"}},
        {name: "Booking request received", filters: {tag: "landlord_booking_request_sent"}}, {name: "Booking request rejected - student - motive: landlord", filters: {tag: "student_booking_rejected", motive: "landlord"}}, {name: "Booking request rejected - motive: landlord", filters: {tag: "landlord_booking_rejected", rejectedMotive: "landlord"}}, {name: "Booking request rejected - accepted by other landlord", filters: {tag: "landlord_booking_rejected", rejectedMotive: "accepted_other"}}, {name: "Booking request reminders - landlord 1", filters: {tag: "landlord_booking_reminder", reminderType: 1}}, {name: "Booking request reminders - landlord 12", filters: {tag: "landlord_booking_reminder", reminderType: 12}}, {name: "Booking request sent - student", filters: {tag: "student_booking_request_sent"}}, {name: "Booking request sent - landlord", filters: {tag: "landlord_booking_request_sent"}}, {name: "Confirmation of prices and calendars", filters: {tag: "landlord_property_reminder", type: "confirmation_request"}}, {name: "Info check-ins", filters: {tag: "landlord_booking_accepted", reminderType: "reminder"}}, {name: "Landlord account creation", filters: {tag: "landlord_account_validation"}}, {name: "Landlords - Booking cancelled - cancellationType: \"landlord\"", filters: {tag: "landlord_booking_cancellation", cancellationType: "landlord"}}, {name: "Landlords - Booking cancelled - cancellationType: \"tenant\"", filters: {tag: "landlord_booking_cancellation", cancellationType: "tenant"}}, {name: "Landlords - Booking cancelled - Inlife (not Working)", filters: {tag: ""}},
        {name: "Landlords - New offer sent to tenant", filters: {tag: "landlord_offer_sent"}}, {name: "Landlords - Reply Inlife - Request cancellation - 0-24 horas", filters: {tag: "landlord_booking_cancellation_response"}}, {name: "Landlords - Request cancellation - 0-24 horas", filters: {tag: "landlord_booking_cancellation_request"}}, {name: "Landlords - weekly alert listing potential tenants ", filters: {tag: "landlord_potential_tenants_weekly"}}, {name: "More information's validation request", filters: {tag: "management_notification", type: "moreInfo"}}, {name: "New account creation notification", filters: {tag: "management_notification", notificationType: "new_landlord"}}, {name: "New property created", filters: {tag: "management_notification", notificationType: "new_ad"}}, {name: "New property finished (Not Working)", filters: {}}, {name: "New room inserted in existing property", filters: {tag: "management_property_validation"}}, {name: "Promocode auto email", filters: {tag: "student_promo_code"}}, {name: "Reset landlord password", filters: {tag: "landlord_recover_password"}}, {name: "Rising stars", filters: {tag: "landlord_status"}}, {name: "Suggestion - New listing accepted - student", filters: {tag: "student_visit_confirmed"}},
        {name: "Suggestion - New listing accepted - landlord", filters: {tag: "landlord_visit_request", type: "suggestion_accepted"}}, {name: "Suggestion - New listings accepted - Price changed (instant)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "property", priceChanged: true}}, {name: "Suggestion - New Listing accepted - Price changed - Please pay (Request)", filters: {tag: "student_booking_suggestion_accepted", suggestionType:"property", priceChanged: true}}, {name: "Suggestion - New listing accepted - Price not changed (instant)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "property", bookingType: "instant booking"}}, {name: "Suggestion - New listings accepted - Price not changed - Please pay (Request)", filters: {tag: "student_booking_suggestion_accepted", suggestionType:"property"}}, {name: "Suggestion - New listings expired - landlord-visit - tenant_timout", filters: {tag: "landlord_visit_cancelled", rejectedMotive: "tenant_timout"}}, {name: "Suggestion - New listing expired - landlord-booking", filters: {tag: "landlord_booking_suggestion_rejected", suggestionType: "property", rejectionType: "expired"}}, {name: "Suggestion - New listing expired - student-visit", filters: {tag: "student_visit_rejected", rejectedMotive: "tenant_timeout"}}, {name: "Suggestion - New listing expired - student-booking", filters: {tag: "student_booking_suggestion_expired", suggestionType: "property"}},
        {name: "Suggestion - New listing rejected - student-booking", filters: {tag: "student_booking_suggestion_rejected", suggestionType: "property"}}, {name: "Suggestion - New listing rejected - landlord-booking", filters: {tag: "landlord_booking_suggestion_rejected", suggestionType: "property", rejectionType: "rejected"}}, {name: "Suggestion - New listing rejected - landlord-visit", filters: {tag: "landlord_visit_cancelled", rejectedMotive: "rejected_suggestion"}}, {name: "Suggestion - New listing rejected - student-visit", filters: {tag: "student_visit_rejected", rejectedMotive: "tenant"}}, {name: "Suggestion - New listing suggested - landlord-booking", filters: {tag: "landlord_booking_suggestion_accepted", suggestionType: "property"}}, {name: "Suggestion - New listing suggested - landlord-visit", filters: {tag: "landlord_visit_suggestion_sent", suggestionType: "property"}}, {name: "Suggestion - New listing suggested - student - suggestionType: \"dates\"", filters: {tag: "student_visit_suggestion", suggestionType: "dates"}}, {name: "Suggestion - New lsiting suggested - student", filters: {tag: "student_booking_ad_suggested"}}, {name: "Suggestion - New dates accepted - student-visit", filters: {tag: "student_visit_confirmed"}}, {name: "Suggestion - New dates accepted - landlord-booking", filters: {tag: "landlord_booking_suggestion_accepted", suggestionType:"dates"}}, {name: "Suggestion - New dates accepted - landlord-visit", filters: {tag: "landlord_visit_confirmed"}},
        {name: "Suggestion - New dates accepted - Price changed - Please pay (Instant)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "dates", priceChanged: true}}, {name: "Suggestion - New dates accepted - Price changed - Please pay (Request)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "dates", priceChanged: true}}, {name: "Suggestion - New dates accepted - Price not changed (Instant)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "dates"}}, {name: "Suggestion - New dates accepted - Price not changed (request)", filters: {tag: "student_booking_suggestion_accepted", suggestionType: "dates"}}, {name: "Suggestion - New dates confirmed - landlord-visit", filters: {tag: "landlord_visit_confirmed"}}, {name: "Suggestion - New dates expired - landlord-visit", filters: {tag: "landlord_visit_cancelled", rejectedMotive: "tenant_timout"}}, {name: "Suggestion - New dates expired - landlord-booking", filters: {tag: "landlord_booking_suggestion_rejected", suggestionType: "dates", rejectionType: "expired"}}, {name: "Suggestion - New dates expired - student-visit", filters: {tag: "student_visit_rejected", rejectedMotive: "tenant_timeout"}}, {name: "Suggestion - New dates expired - student-booking", filters: {tag: "student_booking_suggestion_expired", suggestionType: "dates"}}, {name: "Suggestion - New dates of stay suggested (Not Working)", filters: {}},
        {name: "Suggestion - New dates rejected - landlord-booking", filters: {tag: "landlord_booking_suggestion_rejected", suggestionType: "dates", rejectionType: "rejected"}}, {name: "Suggestion - New dates rejected - landlord-visit", filters: {tag: "landlord_visit_cancelled", rejectedMotive: "rejected_suggestion"}}, {name: "Suggestion - New dates rejected - student-visit", filters: {tag: "student_visit_rejected", rejectedMotive: "tenant"}}, {name: "Suggestion - New dates rejected - student-booking", filters: {tag: "student_booking_suggestion_rejected", suggestionType: "dates"}}, {name: "Suggestion - New dates suggested - student-booking", filters: {tag: "student_booking_dates_suggested"}}, {name: "Suggestion - New dates suggested - landlord-visit", filters: {tag: "landlord_visit_suggestion_sent", suggestionType: "dates"}}, {name: "Suggestion - New dates suggested - landlord-booking", filters: {tag: "landlord_booking_suggestion_sent"}}, {name: "Suggestion - New visit dates suggested - student", filters: {tag: "student_visit_suggestion", suggestionType:"dates"}}, {name: "Suggestion - New visit dates suggested - landlord", filters: {tag: "landlord_visit_suggestion_sent", suggestionType: "dates"}}, {name: "Tenant - New advisor offer received", filters: {tag: "student_recommendation", type: "advisor_recommendation"}}, {name: "Tenant - New landlord offer received", filters: {tag: "student_recommendation", type: "landlord_offer"}}, {name: "Tenant Welcome (notWorking)", filters: {}},
        {name: "Tenants -  Reply Inlife Request cancellation - 0-24 horas", filters: {tag: "student_booking_cancellation_response"}}, {name: "Tenants - Booking cancelled - cancellationType: \"landlord\"", filters: {tag: "student_booking_cancellation", cancellationType: "landlord"}}, {name: "Tenants - Booking cancelled - cancellationType: \"tenant\"", filters: {tag: "student_booking_cancellation", cancellationType: "tenant"}}, {name: "Tenants - Request cancellation - 0-24 horas", filters: {tag: "student_booking_cancellation_request"}}, {name: "Top landlords", filters: {tag: "landlord_status"}}, {name: "Visit cancelled - student", filters: {tag: "student_visit_cancelled"}}, {name: "Visit cancelled - landlord", filters: {tag: "landlord_visit_cancelled", rejectedAfterSchedule: true}}, {name: "Visit reminder - landlord 1 hour", filters: {tag: "landlord_visit_1_hour"}}, {name: "Visit reminder - 1 day before - student", filters: {tag: "student_visit_tomorrow"}}, {name: "Visit reminder - 1 day before - landlord (Not Working)", filters: {}}, {name: "Visit reminder - 1 hour before - student", filters: {tag: "student_visit_1_hour"}}, {name: "Visit request confirmation - landlord", filters: {tag: "landlord_visit_confirmed"}}, {name: "Visit request confirmation - student", filters: {tag: "student_visit_confirmed"}}, {name: "Visit request expired - student - landlord_timeout", filters: {tag: "student_visit_rejected", rejectedMotive: "landlord_timeout"}}, {name: "Visit request expired - landlord - landlord_timeout", filters: {tag: "landlord_visit_cancelled",  rejectType:"landlord_timeout"}},
        {name: "Visit request received - landlord", filters: {tag: "landlord_visit_request"}}, {name: "Visit request rejected - student", filters: {tag: "student_visit_rejected",  rejectedMotive: "landlord"}}, {name: "Visit request rejected - landlord", filters: {tag: "landlord_visit_cancelled", rejectType: "landlord"}}, {name: "Visit request sent - student", filters: {tag: "student_visit_request_sent"}},{name: "Landlord - Deleted Account", filters: {tag: "management_notification", type: "landlordDeleted"}}
    ];

    const emailName = emailFilterByName.map(t => ({value: t.name, label: t.name}));

    useEffect(() => {
        (async () => {
            setRegions((await dataService.getRegions()).map(r => ({value: r, label: r})));
        })();
        (async () => {
            let _nationalities = (await dataService.getCountries()).map(c => ({value: c.iso2, label: c.name}));
            _nationalities.push({value:"undefined", label:"Undefined"})
            setNationalities(_nationalities);
        })();
        (async () => {
            let _universities = await dataService.getUniversities()
            _universities = [].concat(...Object.keys(_universities).map(region => _universities[region])).map(u => ({value: u, label: u}))
            _universities.push({value: "undefined", label: "Undefined"})
            setUniversities(_universities);
        })();
        if(type === "listings") {
            (async () => {
            let _neighborhoods = await dataService.getNeighborhoods()
            setNeighborhoods(Object.keys(_neighborhoods).map(r => ({
                options: _neighborhoods[r].map(n => ({
                    value: n,
                    label: n
                })), label: r
            })));
        })();
        }
        (async () => {
            if(type === "listings") setLandlords((await landlordService.list(null, null, null, 10000)).landlords.map(l => ({value: l.id, label: l.name})));
        })();
        (async () => {
            setIntegrationPlatforms((await dataService.getIntegrationPlatforms()).map(p => ({value: p, label: p})));
        })();
    }, [])

    return (<div className={"FiltersSection"}>
        {showFilters ? <>
            <div className={"d-flex"}>
                <button className={"btn btn-secondary mb-1 mr-1"} onClick={() => setShowFilters(false)}>Hide</button>
                <button className={"btn btn-danger mb-1"} onClick={() => {
                    setFilters({});
                    localStorage.removeItem(type + "_filters");
                }}>Clear filters</button>
            </div>
            <div className={"d-flex flex-wrap"}>
                {["reservations", "visits"].includes(type) &&
                <div className={"select-input"}>
                    <label htmlFor={"currentStatus"}>State</label>
                    <Select name={"currentStatus"} isClearable
                            options={type === "reservations" ? reservationStatusOptions : visitStatusOptions}
                            value={(type === "reservations" ? reservationStatusOptions : visitStatusOptions).filter(o => o.value === filters["currentStatus"])}
                            className={"react-select-container"} classNamePrefix={"react-select"}
                            onChange={(o) => {
                                changeFilters("currentStatus", o?.value)
                            }}/>
                </div>}
                {["reservations", "visits"].includes(type) &&
                <div className={"select-input"}>
                    <label htmlFor={"type"}>Type</label>
                    <Select name={"type"} isClearable
                            options={type === "reservations" ? reservationTypes : visitTypes}
                            value={(type === "reservations" ? reservationTypes : visitTypes).filter(t => t.value === filters["type"])}
                            className={"react-select-container"} classNamePrefix={"react-select"}
                            onChange={(o) => {
                                changeFilters("type", o?.value)
                            }}/>
                </div>}
                {!["invoices", "emails", "payments"].includes(type) &&
                <div className={"select-input"}>
                    <label htmlFor={"regions"}>Cities</label>
                    <Select isMulti name={"regions"} isClearable options={regions} className={"react-select-container"}
                            classNamePrefix={"react-select"}
                            value={regions.filter(t => filters["regions"]?.includes(t.value))}
                            onChange={(o) => {
                                changeFilters("regions", o?.map(t => t.value))
                            }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                </div>}
                {["emails"].includes(type) &&
                    <div className={"select-input"}>
                        <label htmlFor={"language"}>Language</label>
                        <Select name={"languague"} isClearable options={languagesOptions} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={languagesOptions.filter(t => filters["language"]?.includes(t.value))}
                                onChange={(o) => {
                                    changeFilters("language", o?.value)
                                }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                    </div>}
                {["emails"].includes(type) &&
                    <div className={"select-input"}>
                        <label htmlFor={"emailType"}>Type</label>
                        <Select name={"emailType"} isClearable options={emailTypes} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={emailTypes.filter(t => filters["emailType"]?.includes(t.value))}
                                onChange={(o) => {
                                    changeFilters("emailType", o?.value)
                                }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                    </div>}
                {["emails"].includes(type) &&
                    <div className={"select-input"}>
                        <label htmlFor={"emailTag"}>Tag</label>
                        <Select name={"emailTag"} isClearable options={emailTag} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={emailTag.filter(t => filters["emailTag"]?.includes(t.value))}
                                onChange={(o) => {
                                    changeFilters("emailTag", o?.value)
                                }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                    </div>}
                {["emails"].includes(type) &&
                    <div className={"select-input"}>
                        <label htmlFor={"emailName"}>Name</label>
                        <Select name={"emailName"} isClearable options={emailName} className={"react-select-container"}
                                classNamePrefix={"react-select"} isMulti={false}
                                value={emailName.filter(t => filters["emailName"]?.includes(t.value))}
                                onChange={(o) => {
                                    changeFilters("emailName", o?.value)
                                }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                    </div>}
                {type === "landlords" && <div className={"select-input"}>
                    <label htmlFor={"countries"}>Countries</label>
                    <Select isMulti name={"countries"} isClearable options={["portugal", "spain", "italy"].map(c => ({value:c, label: c}))} className={"react-select-container"}
                            classNamePrefix={"react-select"}
                            value={["portugal", "spain", "italy"].map(c => ({value:c, label: c})).filter(t => filters["countries"]?.includes(t.value))}
                            onChange={(o) => {
                                changeFilters("countries", o?.map(c => c.value))
                            }}/>
                </div>}
                {["reservations", "visits", "finance"].includes(type) &&
                <DateRanger label={"Requests from"} filters={filters} setFilters={changeFilters}
                            startFilter={"requestDate"} endFilter={"endRequestDate"}/>}
                {["reservations", "tenants", "finance"].includes(type) &&
                <DateRanger label={"Stay dates"} filters={filters} setFilters={changeFilters} startFilter={"moveIn"}
                            endFilter={"moveOut"}/>}
                {type === "visits" &&
                <DateRanger label={"Visit Dates"} filters={filters} setFilters={changeFilters} startFilter={"visitDay"}
                            endFilter={"endVisitDay"}/>}
                {type === "tenants" || type === "landlords" &&
                <DateRanger label={"Account created"} filters={filters} setFilters={changeFilters}
                            startFilter={"registration"} endFilter={"endRegistration"}/>}
                {["integrations"].includes(type) &&
                    <div className={"select-input"}>
                        <label htmlFor={"integrationPlatform"}>Platform</label>
                        <Select name={"integrationPlatform"} isClearable options={integrationPlatforms} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={integrationPlatforms.filter(t => filters["integrationPlatform"]?.includes(t.value))}
                                onChange={(o) => {
                                    changeFilters("integrationPlatform", o?.value)
                                }}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'}/>
                    </div>}
                {["tenants", "listings"].includes(type) && <div className={"select-input"}>
                    <label htmlFor={"accommodation"}>Accommodation</label>
                    <Select name={"accommodation"} isClearable
                            options={accommodationTypes}
                            value={accommodationTypes.filter(a => a.value === filters["accommodation"])}
                            className={"react-select-container"} classNamePrefix={"react-select"}
                            onChange={(o) => {
                                changeFilters("accommodation", o?.value)
                            }}/>
                </div>}
                {type === "tenants" &&
                <>
                    <div className={"select-input"}>
                        <label htmlFor={"rating"}>Rating</label>
                        <Select name={"rating"} isClearable
                                options={[1, 2, 3, 4, 5].map(r => ({value: r, label: r}))}
                                value={[1, 2, 3, 4, 5].map(r => ({
                                    value: r,
                                    label: r
                                })).filter(r => r.value === filters["rating"])}
                                className={"react-select-container"} classNamePrefix={"react-select"}
                                onChange={(o) => {
                                    changeFilters("rating", o?.value)
                                }}/>
                    </div>
                    <div className={"select-input"}>
                        <label htmlFor={"university"}>University</label>
                        <Select name={"university"} isClearable options={universities} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={universities.filter(u => u.value === filters["university"])}
                                onChange={(o) => {
                                    changeFilters("university", o?.value)
                                }}/>
                    </div>
                    <div className={"select-input"}>
                        <label htmlFor={"nationality"}>Nationality</label>
                        <Select name={"nationality"} isClearable options={nationalities} className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={nationalities.filter(u => u.value === filters["nationality"])}
                                onChange={(o) => {
                                    changeFilters("nationality", o?.value)
                                }}/>
                    </div>
                    <div className={"select-input"}>
                        <label htmlFor={"occupation"}>Occupation</label>
                        <Select name={"occupation"} isClearable
                                options={userOccupations}
                                value={userOccupations.filter(o => o.value === filters["occupation"])}
                                className={"react-select-container"} classNamePrefix={"react-select"}
                                onChange={(o) => {
                                    changeFilters("occupation", o?.value)
                                }}/>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <label htmlFor="budget" className={"mb-3"}>Budget: {filters["budget"] || ""}</label>
                        <input type="range" id="budget" name="budget" min="0" max="2000"
                               value={filters["budget"] || "0"} step="100" onChange={(e) => {
                            e.persist();
                            changeFilters("budget", e.target.value)
                        }} />
                    </div>
                    <CheckBox label={"Has Reservations"} filter={"hasReservations"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Has Visits"} filter={"hasVisits"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Has Offers"} filter={"hasOffers"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Show Deleted"} filter={"showDeleted"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Show Archived"} filter={"showArchived"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Has Video"} filter={"presentationVideo"} filters={filters} setFilters={changeFilters} />
                    <OrderBy label={"Last Login"} filter={"lastLogin"} filters={filters}
                             setFilters={changeFilters}/>
                    <OrderBy label={"Recent Request"} filter={"reservationRequest"} filters={filters}
                             setFilters={changeFilters}/>
                </>}
                {type === "landlords" &&
                <>
                    <div className={"select-input"}>
                        <label htmlFor={"accountLevel"}>Level</label>
                        <Select name={"accountLevel"} isClearable
                                options={landlordLevels}
                                value={landlordLevels.filter(o => o.value === filters["accountLevel"])}
                                className={"react-select-container"} classNamePrefix={"react-select"}
                                onChange={(o) => {
                                    changeFilters("accountLevel", o?.value)
                                }}/>
                    </div>
                    <OrderBy label={"Accepted Ratio"} filter={"acceptedRatio"} filters={filters}
                             setFilters={changeFilters}/>
                    <OrderBy label={"Active Listings"} filter={"numAds"} filters={filters} setFilters={changeFilters} />
                    <OrderBy label={"Num Requests"} filter={"reservations"} filters={filters} setFilters={changeFilters} />
                    <OrderBy label={"Num Confirmed"} filter={"confirmed"} filters={filters} setFilters={changeFilters} />
                    <OrderBy label={"Commission"} filter={"commission"} filters={filters} setFilters={changeFilters}/>
                    <CheckBox label={"Pending Reservations"} filter={"hasReservations"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Pending Visits"} filter={"hasVisits"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Has Promotion"} filter={"hasPromotion"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Deleted"} filter={"deleted"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Active Landlord"} filter={"activeLandlord"} filters={filters} setFilters={changeFilters} />
                </>}
                {["listings", "integrations"].includes(type) &&
                <>
                    {type === "listings" && <div className={"select-input"}>
                        <label htmlFor={"neighborhood"}>Neighborhood</label>
                        <Select name={"neighborhood"} isClearable options={neighborhoods}
                                className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={[].concat(...neighborhoods.map(r => r.options)).filter(n => n.value === filters ["neighborhood"])}
                                onChange={(o) => {
                                    changeFilters("neighborhood", o?.value)
                                }}/>
                    </div>}
                    {type === "listings" && <div className={"select-input"}>
                        <label htmlFor={"landlord"}>Landlord</label>
                        <Select name={"landlord"} isClearable options={landlords}
                                className={"react-select-container"}
                                classNamePrefix={"react-select"}
                                value={landlords.filter(l => l.value === filters ["landlord"])}
                                onChange={(o) => {
                                    changeFilters("landlord", o?.value)
                                }}/>
                    </div>}
                    {type === "listings" && <div className={"select-input"}>
                        <label htmlFor={"houseState"}>House State</label>
                        <Select name={"houseState"} isClearable
                                options={houseStates}
                                value={houseStates.filter(o => o.value === filters["houseState"])}
                                className={"react-select-container"} classNamePrefix={"react-select"}
                                onChange={(o) => {
                                    changeFilters("houseState", o?.value)
                                }}/>
                    </div>}
                    <div className={"d-flex flex-column"}>
                        <label htmlFor="lastUpdated" className={"mb-3"}>Updated in the last: {filters["lastUpdated"] || "..."} days</label>
                        <input type="range" id="lastUpdated" name="lastUpdated" min="0" max="120"
                               value={filters["lastUpdated"] || "0"} step="5" onChange={(e) => {
                            e.persist();
                            changeFilters("lastUpdated", e.target?.value)
                        }}/>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <label htmlFor="people" className={"mb-3"}>Num people: {filters["people"] || "..."}</label>
                        <input type="range" id="people" name="people" min="0" max="10"
                               value={filters["people"] || "0"} step="1" onChange={(e) => {
                            e.persist();
                            changeFilters("people", e.target?.value)
                        }}/>
                    </div>
                    <OrderBy label={"Last Updated"} filter={"lastUpdatedSort"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Top Landlord"} filter={"topLandlord"} filters={filters} setFilters={changeFilters} />
                    {type === "listings" && <CheckBox label={"Verified"} filter={"verified"} filters={filters} setFilters={changeFilters} />}
                    <CheckBox label={"Instant Booking"} filter={"instantBooking"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Bills Included"} filter={"billsIncluded"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Laundry Service"} filter={"laundryServiceIncluded"} filters={filters} setFilters={changeFilters} />
                    <CheckBox label={"Private Bathroom"} filter={"privateBathroom"} filters={filters} setFilters={changeFilters} />
                    {type === "listings" && <CheckBox label={"Needs Validation"} filter={"needsValidation"} filters={filters} setFilters={changeFilters} />}
                    {type === "listings" && <CheckBox label={"Has Fixed Dates"} filter={"hasAvailability"} filters={filters} setFilters={changeFilters} />}
                </>
                }
                {type === "finance" && <div className={"select-input"}>
                    <label htmlFor="moveInYear">Year</label>
                    <Select name={"moveInYear"}
                            className={"react-select-container"} classNamePrefix={"react-select"}
                            isClearable
                            options={[...Array((moment().year() + 2) - 2021).keys()]
                                .map(i => i + 2021)
                                .map(year => ({value:year, label: year}))}
                            defaultValue={filters["moveInYear"] && [{value:filters["moveInYear"], label:filters["moveInYear"]}]}
                            onChange={(o) => {
                                changeFilters("moveInYear", o?.value)
                            }}
                            placeholder={"Move In Year"}

                    />
                </div>
                }
                {type === "edit_listings" && <>
                    <DateRanger label={"Available from"} filters={filters} setFilters={changeFilters}
                                startFilter={"availableFrom"} endFilter={"endAvailableFrom"}/>

                </>}
            </div>
        </> : <button className={"btn btn-primary"} onClick={() => setShowFilters(true)}>Filters</button>}
    </div>)
}

export default FiltersSection;
