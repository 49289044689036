import React, {useEffect, useMemo, useRef, useState} from "react";
import moment from 'moment';
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import {useAuth} from "../../services/authService.js";
import {useLocation, useParams} from "react-router-dom/cjs/react-router-dom.js";
import PageWrapper from "../components/PageWrapper.js";
import {landlordService} from "../../services/landlordService.js";
import {propertyService} from "../../services/propertyService";
import {ReactGrid} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import Spinner from "../shared/Spinner.js";
import {Pagination} from "react-bootstrap";

const useQuery = () => {
    let searchParams = {};
    new URLSearchParams(useLocation().search).forEach((value, key) => {
        searchParams[key] = value;
    });
    return searchParams;
};

const isValidURL = (str) => {
    let res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null);
};

const LandlordEditListings = () => {
    const {setModal} = useDialogContext();
    let {user} = useAuth();
    let {landlord_id} = useParams();
    let {page: _defaultPage} = useQuery();

    let [listings, setListings] = useState(null);
    let [total, setTotal] = useState(0);
    let [page, setPage] = useState(parseInt(_defaultPage || 1));
    let [pageSize, setPageSize] = useState(parseInt(localStorage.getItem("landlord_edit_listings_page_size") || 50));
    let [filters, setFilters] = useState(JSON.parse(localStorage.getItem("edit_listings_filters")) || {});
    const searchTimeout = useRef(null);
    const [searchKey, setSearchKey] = useState("");
    let [loading, setLoading] = useState(true);
    let [landlord, setLandlord] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                let _landlord = await landlordService.getLandlordById(landlord_id);
                setLandlord(_landlord);
            } catch (e) {
                cogoToast.error("Error getting landlord");
            }
        })();
    }, [landlord_id])

    const loadData = async (page, searchKey, filters, pageSize) => {
        setLoading(true);
        try {
            let _listings = await landlordService.getElasticListings(landlord_id, page - 1, searchKey, filters, pageSize);
            let overrideChanges = JSON.parse(localStorage.getItem("landlord_edit_listings_override_changes")||"{}");
            setListings(_listings.listings.map(listing => {
                let listing_id = listing.id + "_" + listing.room_id;
                if (overrideChanges[listing_id]) {
                    if (overrideChanges[listing_id].lastElasticUpdate <= (Date.now() - 1800000) || overrideChanges[listing_id].lastElasticUpdate <= listing.lastElasticUpdate) {
                        delete overrideChanges[listing_id];
                    } else {
                        listing = {...listing, ...overrideChanges[listing_id]};
                    }
                }
                return listing;
            }));
            localStorage.setItem("landlord_edit_listings_override_changes", JSON.stringify(overrideChanges));
            setTotal(_listings.total);
        } catch (e) {
            cogoToast.error("Error getting listings");
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            setPage(1);
            await loadData(1, searchKey, filters, pageSize);
        })();
    }, [searchKey, filters, pageSize]);

    let defaultColumns = {
        "address": 300,
        "internal_name": 110,
        "room_internal_name": 110,
        "ical": 90,
        "ical_copy": 90,
        "available_from": 120,
        "fixed_rent": 80,
        "rent": 92,
        "rent_jan": 92,
        "rent_feb": 92,
        "rent_mar": 92,
        "rent_apr": 92,
        "rent_may": 92,
        "rent_jun": 92,
        "rent_jul": 92,
        "rent_aug": 92,
        "rent_sep": 92,
        "rent_oct": 92,
        "rent_nov": 92,
        "rent_dec": 92,
        "bills": 142,
        "bills_value": 92,
        "bills_internet": 80,
        "bills_tv": 80,
        "bills_water": 80,
        "bills_eletricity": 80,
        "bills_gas": 80,
        "bills_condominium": 80,
        "administrative_fee_type": 142,
        "administrative_fee_value": 92,
        "deposit_type": 142,
        "deposit": 92,
        "extra_tenant_price": 92,
        "preview": 70
    }

    let _columns = JSON.parse(localStorage.getItem("landlord_edit_listings_columns") || "null");
    if(!_columns) {
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(defaultColumns));
        _columns = defaultColumns;
    } else if (Object.keys(_columns).length !== Object.keys(defaultColumns).length) {
        _columns = {...defaultColumns, ..._columns};
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(_columns));
    }

    const [columns, setColumns] = useState(Object.keys(_columns).map(columnId => {
        return { columnId, width: _columns[columnId], resizable: true };
    }));

    const handleResizeColumn = (columnId, width) => {
        setColumns(prev => {
            const columnIndex = prev.findIndex(el => el.columnId === columnId);
            const resizedColumn = prev[columnIndex];
            prev[columnIndex] = {...resizedColumn, width};
            return [...prev];
        });
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(columns.reduce((acc, column) => {
            if(column.columnId === columnId) {
                acc[columnId] = Math.round(width);
            } else {
                acc[column.columnId] = column.width;
            }
            return acc;
        }, {})));
    }

    const changeEntirePropertyValue = async (new_listings, overrideChanges, property_id, key, value) => {
        new_listings.filter(listing => listing.id === property_id).forEach(listing => {
            let listing_id = listing.id + "_" + listing.room_id;
            if(!overrideChanges[listing_id]) {
                overrideChanges[listing_id] = {};
            }
            overrideChanges[listing_id][key] = value;
            listing[key] = value;
        });
    }

    const deleteEntirePropertyValue = async (new_listings, overrideChanges, property_id, key) => {
        new_listings.filter(listing => listing.id === property_id).forEach(listing => {
            let listing_id = listing.id + "_" + listing.room_id;
            if(overrideChanges[listing_id]) {
                delete overrideChanges[listing_id][key];
            }
            delete listing[key];
        });
    }

    const handleCellsChanged = async (changes) => {
        let overrideChanges = JSON.parse(localStorage.getItem("landlord_edit_listings_override_changes")||"{}");
        let new_listings = [...listings];
        let listing_changes = {};
        let has_changes = false;
        changes.forEach((change) => {
            let listing = new_listings[change.rowId];
            let listing_id = listing.id + "_" + listing.room_id;
            if(!listing_changes[listing_id]) {
                listing_changes[listing_id] = {
                    room_changes: {},
                    property_changes: {}
                };
            }
            if(!overrideChanges[listing_id]) {
                overrideChanges[listing_id] = {};
            }

            if (change.columnId === "rent" && listing.fixedRent) {
                let rent_value = change.newCell.value;
                if(rent_value < 0 || isNaN(rent_value) || rent_value > 999999) {
                    cogoToast.error("Invalid rent value. Row: " + (change.rowId + 1) + " Value: " + rent_value);
                    rent_value = listing.rent;
                }
                listing.rent = rent_value;
                overrideChanges[listing_id].rent = rent_value;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                listing_changes[listing_id].room_changes.rent = rent_value;
                has_changes = true;
            }
            if (change.columnId.startsWith("rent_") && !listing.fixedRent) {

                let rent_value = change.newCell.value;
                let month = moment().locale("en").startOf("month").month(change.columnId.split("_")[1]).month();
                if(rent_value < 0 || isNaN(rent_value) || rent_value > 999999) {
                    cogoToast.error("Invalid month rent value. Month: " + month + " Row: " + (change.rowId + 1) + " Value: " + rent_value);
                    rent_value = listing.rentMonths[month];
                }
                listing.rentMonths[month] = rent_value;
                overrideChanges[listing_id].rentMonths = listing.rentMonths;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                listing_changes[listing_id].room_changes.rentMonths = listing.rentMonths.reduce((acc, rent, i) => {
                    let month_full = moment().locale("en").startOf("month").month(i).format("MMMM").toLowerCase();
                    acc[month_full] = rent;
                    return acc;
                }, {});
                has_changes = true;
            }
            if (change.columnId === "fixed_rent") {
                listing.fixedRent = !!change.newCell.checked;
                listing_changes[listing_id].room_changes.fixedRent = listing.fixedRent;
                overrideChanges[listing_id].fixedRent = listing.fixedRent;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }

            if (change.columnId === "bills") {
                listing._billsOpen = change.newCell.isOpen;

                if (change.newCell.selectedValue && change.newCell.selectedValue !== change.previousCell.selectedValue) {
                    listing.billsIncluded = change.newCell.selectedValue !== "no";
                    if (change.newCell.selectedValue === "yes_some") {
                        delete listing.billsSpecific;
                        listing_changes[listing_id].property_changes.billsSpecific = "$_REMOVE_$";
                        deleteEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsSpecific");
                        listing.billsMax = listing.billsMax || 0;
                        listing_changes[listing_id].property_changes.billsMax = listing.billsMax;
                        changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsMax", listing.billsMax);
                    }
                    if (change.newCell.selectedValue === "yes_specific") {
                        delete listing.billsMax;
                        listing_changes[listing_id].property_changes.billsMax = "$_REMOVE_$";
                        deleteEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsMax")
                        listing.billsSpecific = listing.billsSpecific || [];
                        listing_changes[listing_id].property_changes.billsSpecific = listing.billsSpecific;
                        changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsSpecific", listing.billsSpecific);
                    }
                    if (change.newCell.selectedValue === "no") {
                        delete listing.billsMax;
                        listing_changes[listing_id].property_changes.billsMax = "$_REMOVE_$";
                        deleteEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsMax")
                        delete listing.billsSpecific;
                        listing_changes[listing_id].property_changes.billsSpecific = "$_REMOVE_$";
                        deleteEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsSpecific")
                    }
                    listing_changes[listing_id].property_changes.billsIncluded = listing.billsIncluded;
                    changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsIncluded", listing.billsIncluded);
                    changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "lastElasticUpdate", Date.now());
                    has_changes = true;
                }
            }

            if (change.columnId === "bills_value" && listing.billsIncluded && listing.billsMax !== change.newCell.value) {
                let bills_value = change.newCell.value;
                if(bills_value < 0 || isNaN(bills_value) || bills_value > 999999) {
                    cogoToast.error("Invalid bills value. Row: " + (change.rowId + 1) + " Value: " + bills_value);
                    bills_value = listing.billsMax;
                }
                listing.billsMax = bills_value;
                listing_changes[listing_id].property_changes.billsMax = listing.billsMax;
                changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsMax", listing.billsMax);
                changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "lastElasticUpdate", Date.now());
                has_changes = true;
            }

            ['internet', 'tv', 'water', 'electricity', 'gas', 'condominium'].forEach((specific) => {
                if (change.columnId === "bills_" + specific) {
                    if (listing.billsIncluded && listing.billsSpecific) {
                        if (change.newCell.checked && !listing.billsSpecific.includes(specific)) {
                            listing.billsSpecific.push(specific);
                        } else if (!change.newCell.checked && listing.billsSpecific.includes(specific)) {
                            listing.billsSpecific = listing.billsSpecific.filter(s => s !== specific);
                        }
                        listing_changes[listing_id].property_changes.billsSpecific = listing.billsSpecific;
                        changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "billsSpecific", listing.billsSpecific);
                        changeEntirePropertyValue(new_listings, overrideChanges, listing.id, "lastElasticUpdate", Date.now());
                        has_changes = true;
                    }
                }
            });

            if (change.columnId === "administrative_fee_type") {
                listing._adminFeeOpen = change.newCell.isOpen;

                if (change.newCell.selectedValue && change.newCell.selectedValue !== change.previousCell.selectedValue) {
                    listing.extraAdministrativeFeeType = change.newCell.selectedValue;
                    listing_changes[listing_id].room_changes.extraAdministrativeFeeType = listing.extraAdministrativeFeeType;
                    overrideChanges[listing_id].extraAdministrativeFeeType = listing.extraAdministrativeFeeType;
                    if (change.newCell.selectedValue === "specific_value") {
                        listing.extraAdministrativeFee = listing.extraAdministrativeFee || 0;
                        listing_changes[listing_id].room_changes.extraAdministrativeFee = listing.extraAdministrativeFee;
                        overrideChanges[listing_id].extraAdministrativeFee = listing.extraAdministrativeFee;
                    }
                    overrideChanges[listing_id].lastElasticUpdate = Date.now();
                    has_changes = true;
                }
            }

            if (change.columnId === "administrative_fee_value" && listing.extraAdministrativeFee !== change.newCell.value) {
                let admin_fee_value = change.newCell.value;
                if(admin_fee_value < 0 || isNaN(admin_fee_value) || admin_fee_value > 999999) {
                    cogoToast.error("Invalid administrative fee value. Row: " + (change.rowId + 1) + " Value: " + admin_fee_value);
                    admin_fee_value = listing.extraAdministrativeFee;
                }
                listing.extraAdministrativeFee = admin_fee_value;
                listing_changes[listing_id].room_changes.extraAdministrativeFee = listing.extraAdministrativeFee;
                overrideChanges[listing_id].extraAdministrativeFee = listing.extraAdministrativeFee;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }

            if (change.columnId === "deposit_type") {
                listing._depositOpen = change.newCell.isOpen;

                if (change.newCell.selectedValue && change.newCell.selectedValue !== change.previousCell.selectedValue) {
                    if (change.newCell.selectedValue === "no") {
                        delete listing.securityDeposit;
                        listing_changes[listing_id].room_changes.securityDeposit = "$_REMOVE_$";
                    } else {
                        if (change.newCell.selectedValue === "specific_value") {
                            let val = Number(listing.securityDeposit);
                            if (isNaN(val)) val = 0;
                            listing.securityDeposit = val;
                        } else {
                            listing.securityDeposit = change.newCell.selectedValue;
                        }
                        listing_changes[listing_id].room_changes.securityDeposit = listing.securityDeposit;
                    }

                    overrideChanges[listing_id].securityDeposit = listing.securityDeposit;
                    overrideChanges[listing_id].lastElasticUpdate = Date.now();
                    has_changes = true;
                }
            }

            if (change.columnId === "deposit" && listing.securityDeposit !== change.newCell.value) {
                listing.securityDeposit = change.newCell.value;
                listing_changes[listing_id].room_changes.securityDeposit = listing.securityDeposit;
                overrideChanges[listing_id].securityDeposit = listing.securityDeposit;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }

            if (change.columnId === "extra_tenant_price" && listing.extraTenantsPrice !== change.newCell.value) {
                let extra_tenant_price = change.newCell.value;
                if(extra_tenant_price < 0 || isNaN(extra_tenant_price) || extra_tenant_price > 999999) {
                    cogoToast.error("Invalid extra tenant price value. Row: " + (change.rowId + 1) + " Value: " + extra_tenant_price);
                    extra_tenant_price = listing.extraTenantsPrice;
                }
                listing.extraTenantsPrice = extra_tenant_price;
                listing_changes[listing_id].room_changes.extraTenantsPrice = listing.extraTenantsPrice;
                overrideChanges[listing_id].extraTenantsPrice = listing.extraTenantsPrice;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }

            if (change.columnId === "available_from") {
                let newDate = moment(change.newCell.date);
                if (!moment(listing.availableFrom).isSame(newDate, "day")) {
                    listing.availableFrom = newDate.hour(12).toDate();
                    listing_changes[listing_id].room_changes.availableFrom = listing.availableFrom;
                    overrideChanges[listing_id].availableFrom = listing.availableFrom;
                    overrideChanges[listing_id].lastElasticUpdate = Date.now();
                    has_changes = true;
                }
            }

            if (change.columnId === "ical" && change.newCell.text && (isValidURL(change.newCell.text) || change.newCell.text.toLowerCase() === "remove")) {
                let link = change.newCell.text.replace(/(\r\n|\n|\r)/gm, "");
                listing_changes[listing_id].room_changes.ical = link;
                listing.hasImportedUnavailability = link.toLowerCase() === "remove" ? 0 : 1;
                overrideChanges[listing_id].hasImportedUnavailability = listing.hasImportedUnavailability;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }
        });
        localStorage.setItem("landlord_edit_listings_override_changes", JSON.stringify(overrideChanges));
        setListings(new_listings);
        if(has_changes) {
            try {
                await landlordService.advancedUpdateListings(landlord_id, listing_changes);
                cogoToast.success("Listings updated");
            } catch (e) {
                cogoToast.error("Error updating listings");
            }
        }
    }

    const handleFocusLocationChanging = (location) => {
        if(location.columnId === "preview") {
            let listing = listings[location.rowId];
            let house_path = listing.url_slugs ? listing.url_slugs["en"] : (listing.id + (listing.accommodation === "apartment" ? '' : ("/" + listing.room_id)));
            window.open(process.env.REACT_APP_STUDENT_URL + "/house/" + house_path, '_blank');
            return false;
        }
        if (location.columnId === "ical_copy") {
            let listing = listings[location.rowId];
            if(navigator && navigator.clipboard) {
                propertyService.getListingEventUrls(listing.id, listing.room_id).then((event_urls) => {
                    navigator.clipboard.writeText(event_urls).then(() => {
                        cogoToast.success("iCal URLs copied to clipboard");
                    }).catch(e => {
                        cogoToast.error("Error copying iCal URLs to clipboard");
                        console.error(e);
                    });
                });
            }
        }
        return true;
    }

    const handlePrevPage = () => {
        if(page <= 1) return;
        handleSetPage(page - 1);
    }
    const handleNextPage = () => {
        if(page === Math.floor(total / 100)) return;
        handleSetPage(page + 1);
    }
    const handleSetPage = async (newPage) => {
        setPage(newPage);
        await loadData(newPage, searchKey, filters, pageSize);
    }

    const getBillsIncludedInitialValues = (listing) => {
        if (listing.billsIncluded === undefined) return undefined;
        if (!!listing.billsIncluded && (listing.billsMax !== null && listing.billsMax !== undefined)) return 'yes_some'
        else if (listing.billsIncluded && listing.billsSpecific) return 'yes_specific'
        else return listing.billsIncluded ? 'yes' : 'no';
    }

    useEffect(() => {
        window.history.pushState({}, "", `?page=${page}`);
    }, [page]);

    let pages = useMemo(() => {
        let maxPage = Math.ceil(total/pageSize || 1);
        let ret = [];
        if(maxPage <= 7) {
            for(let np = 1; np <= maxPage; np++) {
                ret.push(np);
            }
        } else {
            let npage = page;
            if(npage < 5)
                ret = [1, 2, 3, 4, 5, "..", maxPage];
            else if(npage > maxPage-4)
                ret = [1, "..", maxPage-4, maxPage-3, maxPage-2, maxPage-1, maxPage];
            else
                ret = [1, "..", npage-1, npage, npage+1, "..", maxPage];
        }
        return ret.map((p,i) => <Pagination.Item key={p+"_"+i} active={p === page} disabled={p===".."} onClick={() => {if(p!=="..")handleSetPage(p)}}>
            {p}
        </Pagination.Item>)
    },[total, page]);

    const calcBackground = (id, disabled) => {
        let hash = 0;
        for (let i = 0; i < id.length; i++) {
            hash = id.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 255;
            value = Math.min(255, value + 215); // Ensure the color is lighter
            color += value.toString(16);
        }
        if(disabled) {
            return color + "66";
        }
        return color;
    }

    return (
        <PageWrapper title={"Edit Listings"}
                     filters={filters} setFilters={setFilters}
                     breadcrumbs={[
                         {url: "landlords", title: "Landlords"},
                         {url: "landlords/" + landlord_id, title: landlord?.name||"Landlord"},
                         {title: "Edit Listings"}
                     ]}
                     noCard>
            <div className={"EditListings-Header"}>
                <div className={"d-flex align-items-center search-container-wrapper"}>
                    <div className="form-group search-container">
                        <input type="search" className="form-control" placeholder="Search"
                               onChange={(e) => {
                                   window.clearTimeout(searchTimeout.current);
                                   let searchedKey = e.target.value;
                                   if (searchedKey == null || (searchedKey.length > 0 && searchedKey.length < 3)) return;
                                   searchTimeout.current = window.setTimeout(() => {
                                       setSearchKey(searchedKey)
                                   }, 400);
                               }}/>
                    </div>
                </div>
                <div className={"d-flex"}>
                    <Pagination>
                        <Pagination.Prev disabled={page === 1} onClick={() => {
                            handlePrevPage();
                        }}/>
                        {pages}
                        <Pagination.Next disabled={Math.ceil(total/pageSize) === page} onClick={() => {
                            handleNextPage();
                        }}/>
                    </Pagination>
                </div>
            </div>
            <div className={"ReactGrid-Wrapper"+(loading?" loading":"")}>
                {loading && <Spinner inline/>}
                <ReactGrid
                    stickyTopRows={1}
                    stickyLeftColumns={3}
                    onColumnResized={handleResizeColumn}
                    onCellsChanged={handleCellsChanged}
                    onFocusLocationChanging={handleFocusLocationChanging}
                    enableRangeSelection
                    disableVirtualScrolling={true}
                    columns={columns}
                    rows={[
                        {
                            rowId: "header",
                            cells: [
                                { type: "header", text: "Address" },
                                { type: "header", text: "Internal Name" },
                                { type: "header", text: "Room Name" },
                                { type: "header", text: "iCal" },
                                { type: "header", text: "iCal Get" },
                                { type: "header", text: "Avail. From" },
                                { type: "header", text: "Fixed Rent" },
                                { type: "header", text: "Rent" },
                                ...(new Array(12).fill(0).map((_, i) => {
                                    return { type: "header", text: moment().locale("en").month(i).format("MMM") }
                                })),
                                { type: "header", text: "Bills" },
                                { type: "header", text: "Value" },
                                { type: "header", text: "Internet" },
                                { type: "header", text: "TV" },
                                { type: "header", text: "Water" },
                                { type: "header", text: "Electricity" },
                                { type: "header", text: "Gas" },
                                { type: "header", text: "Condominium" },
                                { type: "header", text: "Admin Fee Type" },
                                { type: "header", text: "Admin Fee" },
                                { type: "header", text: "Deposit Type" },
                                { type: "header", text: "Deposit" },
                                { type: "header", text: "Extra Tenant" },
                                { type: "header", text: "Preview" }
                            ]
                        },
                        ...(listings||[]).map((listing, i) => {
                            let bills_type = getBillsIncludedInitialValues(listing);
                            let depositType;
                            if (listing.securityDeposit == null) {
                                depositType = "no";
                            } else if (!isNaN(Number(listing.securityDeposit)) ) {
                                depositType = "specific_value";
                            } else {
                                depositType = listing.securityDeposit;
                            }
                            let cellStyle = {background: calcBackground(listing.id)};
                            let cellStyleDisabled = {background: calcBackground(listing.id, true)};
                            return {
                                rowId: i,
                                cells: [
                                    { type: "text", style: cellStyle, text: listing.address||"" },
                                    { type: "text", style: cellStyle, text: listing.internal_name||"" },
                                    { type: "text", style: cellStyle, text: listing.internalName||"" },
                                    { type: "text", style: listing.hasImportedUnavailability ? {background: "#90dd9072"} : {background: "#dd909072"}, placeholder: listing.hasImportedUnavailability ? "Has iCal" : "No iCal", text: "" },
                                    { type: "text", text: listing.hasImportedUnavailability ? "Copy ✂" : "-", className: listing.hasImportedUnavailability ? "clickable-cell" : "disabled-cell" },
                                    { type: "date", style: cellStyle, date: moment(listing.availableFrom).toDate(), format: new Intl.DateTimeFormat('pt-PT') },
                                    { type: "checkbox", style: cellStyle, checked: !!listing.fixedRent },
                                    listing.fixedRent ?
                                        { type: "number", style: cellStyle, value: listing.rent||0, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) } :
                                        { type: "text", text: "-", className: "disabled-cell" },
                                    ...(new Array(12).fill(0).map((_, j) => {
                                        if(listing.fixedRent) return { type: "text", text: "-", className: "disabled-cell" };
                                        return { type: "number", style: cellStyle, value: (listing.rentMonths?.[j])||0, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) }
                                    })),
                                    { type: "dropdown", style: cellStyle, values: [
                                            { value: "yes", label: "1. All" },
                                            { value: "yes_some", label: "2. Up to" },
                                            { value: "yes_specific", label: "3. Specific" },
                                            { value: "no", label: "4. No" }
                                        ], isOpen: !!listing._billsOpen, selectedValue: bills_type },
                                    bills_type === "yes_some" ?
                                        { type: "number", style: cellStyle, value: listing.billsMax, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) } :
                                        { type: "text", text: "-", className: "disabled-cell" },
                                    ...['internet', 'tv', 'water', 'electricity', 'gas', 'condominium'].map(specific => {
                                        return bills_type === "yes_specific" ?
                                            { type: "checkbox", style: cellStyle, checked: !!listing.billsSpecific.includes(specific) } :
                                            { type: "text", text: "-", className: "disabled-cell" }
                                    }),
                                    { type: "dropdown", style: cellStyle, values: [
                                            { value: "no", label: "None" },
                                            { value: "1/2month", label: "1/2 Month" },
                                            { value: "1month", label: "1 Month" },
                                            { value: "3/2months", label: "3/2 Months" },
                                            { value: "2months", label: "2 Months" },
                                            { value: "5/2months", label: "5/2 Months" },
                                            { value: "3months", label: "3 Months" },
                                            { value: "specific_value", label: "Specific" },
                                        ], isOpen: !!listing._adminFeeOpen, selectedValue: listing.extraAdministrativeFeeType },
                                    listing.extraAdministrativeFeeType === "specific_value" ?
                                        { type: "number", style: cellStyle, value: listing.extraAdministrativeFee, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) } :
                                        { type: "text", text: "-", className: "disabled-cell" },
                                    { type: "dropdown", style: cellStyle, values: [
                                            { value: "no", label: "None" },
                                            { value: "1/2month", label: "1/2 Month" },
                                            { value: "1month", label: "1 Month" },
                                            { value: "3/2months", label: "3/2 Months" },
                                            { value: "2months", label: "2 Months" },
                                            { value: "5/2months", label: "5/2 Months" },
                                            { value: "3months", label: "3 Months" },
                                            { value: "specific_value", label: "Specific" },
                                        ], isOpen: !!listing._depositOpen, selectedValue: depositType },
                                    depositType === "specific_value" ?
                                        { type: "number", style: cellStyle, value: listing.securityDeposit, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) } :
                                        { type: "text", text: "-", className: "disabled-cell" },
                                    { type: "number", style: cellStyle, value: listing.extraTenantsPrice, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) },
                                    { type: "text", style: cellStyle, text: "↗", className: "clickable-cell" }
                                ]
                            };
                        })
                    ]}
                />
            </div>
        </PageWrapper>
    );
}

export default LandlordEditListings;
