import React, {useEffect, useState} from "react";
import {landlordService} from "../../services/landlordService";
import {authService, useAuth} from "../../services/authService";
import {useLocation, useParams} from "react-router-dom/cjs/react-router-dom";
import {Form, Tab, Tabs} from "react-bootstrap";
import Visits from "../visits/Visits";
import PageWrapper from "../components/PageWrapper";
import Spinner from "../shared/Spinner";
import Reservations from "../reservations/Reservations";
import LandlordEditForm from "../components/LandlordEditForm";
import cogoToast from "cogo-toast";
import defaultAvatar from "../../assets/images/avatar.png";
import Properties from "../properties/Properties";
import {useDialogContext} from "../App";
import moment from 'moment';

const useQuery = () => {
    let searchParams = {};
    new URLSearchParams(useLocation().search).forEach((value, key) => {
        searchParams[key] = value;
    });
    return searchParams;
};

const LandlordInformation = () => {
    const {setModal} = useDialogContext();
    let {user} = useAuth();

    let {landlord_id} = useParams();

    const query = useQuery();
    const [landlord, setLandlord] = useState(null);
    const [acceptedR, setAcceptedR] = useState(null);
    const [confirmedR, setConfirmedR] = useState(null);
    const [error, setError] = useState(null);

    const [isChecked, setIsChecked] = useState(landlord?.topLandlord);

    useEffect(() => {
        setIsChecked((landlord?.topLandlord === "true" || landlord?.topLandlord === true))
    }, [landlord]);

    const getLandlordVisits = (landlord_id) => async () => {
        return await landlordService.getLandlordVisits(landlord_id)
    }

    const getLandlordProperties = (landlord_id) => async () => {
        return {properties: await landlordService.getLandlordProperties(landlord_id)}
    }

    const getLandlordReservations = (landlord_id) => async () => {
        return  await landlordService.getLandlordReservations(landlord_id)
    }

    useEffect( () => {
        landlordService.getLandlordReservations(landlord_id, 10000).then((res) => {
            setAcceptedR(res.reservations?.filter((reservation) => reservation.acceptedDate)?.length);
            setConfirmedR(res.reservations?.filter((reservation) => reservation.acceptedDate && reservation.payment.status === "paid" && !reservation.rejectedDate && !reservation.cancellation)?.length);
        })
    }, [landlord_id]);


    const editLandlordModal = async () => {
        let _editLandlord={};
        setModal({
            title: landlord.name+"'s information",
            size: "lg",
            message: <LandlordEditForm editLandlord={landlord} setEditLandlord={(cb)=>_editLandlord = cb(_editLandlord)} isChecked={isChecked} setIsChecked={setIsChecked} validateIban={handleValidateIban}/> ,
            onSubmit: async () => {
                let response = await landlordService.editLandlord(landlord_id, _editLandlord);
                setModal(m => ({...m, hide: true}));
                if(response.error) {
                    cogoToast.error(response.error, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                } else {
                    setLandlord(prevLandlord => {
                        return {
                            ...prevLandlord,
                            ..._editLandlord
                        }
                    });
                    cogoToast.info('Landlord edited successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    });
                }
            },
        })
    };

    const impersonateLandlord = async () => {
        let token = await authService.impersonate("landlord", landlord.id);
        window.open(process.env.REACT_APP_LANDLORD_URL + "/backoffice/impersonate?type=landlord&token=" + token, '_blank').focus();
    }

    const advancedEditListings = () => {
        window.location.href += "/listings";
    }

    const [loadingSheetsIntegration, setLoadingSheetsIntegration] = useState(false);

    const fetchAndOpenSheetsIntegration = async () => {
        setLoadingSheetsIntegration(true);
        try {
            let spreadsheetId = await landlordService.getSheetsIntegration(landlord_id);
            setLoadingSheetsIntegration(false);
            if (spreadsheetId) {
                window.open(`https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=0`, '_blank').focus();
            }
        } catch (e) {
            setLoadingSheetsIntegration(false);
            cogoToast.error(e.error.message || e.error, {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error integrating with Google Sheets.'
            });
        }
    }

    const sheetsIntegration = async () => {
        if(loadingSheetsIntegration) return;
        if(landlord.sheets_integration) return fetchAndOpenSheetsIntegration();

        setModal({
            title: "Google Sheets Integration",
            size: "md",
            message: <div>This landlord ({landlord.name}) does <strong>NOT</strong> have an active integration.<br/>Please
                confirm that you want to integrate with Google Sheets for this landlord.</div>,
            onSubmit: async () => {
                await fetchAndOpenSheetsIntegration();
                setModal(m => ({...m, hide: true}));
            }
        });
    }

    useEffect(() => {
        (async ()=>{
            try{
                let _landlord = await landlordService.getLandlordById(landlord_id);
                setLandlord(_landlord);
                console.log(_landlord);
                if(query.validateIban !== null) {
                    //17fdf014-f4e6-43fb-a219-4866954014dd
                    let bankInfoId = query.validateIban;
                    let bankInfo = (_landlord?.multipleBankInfo || [])?.find(info => info.id === bankInfoId);
                    if(bankInfo?.iban && !bankInfo.valid) {
                        handleValidateIban(_landlord, bankInfo);
                    }
                }
                if(query.verifyPhoto) {
                    handlePhotoVerification();
                }
            } catch (e) {
                console.log(e);
                setError(e);
            }
        })();
    }, []);

    const validateIban = async (landlord, bankInfo) => {
        try {
            let response = await landlordService.validateIban(landlord.id, bankInfo.id, bankInfo.iban);
            let multipleBankInfo = landlord.multipleBankInfo || [];
            let index = multipleBankInfo?.findIndex(info => info.id === bankInfo.id);
            if(index !== -1) multipleBankInfo[index] = {...multipleBankInfo[index], "valid": true}
            setLandlord(landlord);
            setModal(m => ({...m, hide: true}));
            if(response.error) {
                cogoToast.error(response.error, {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Something went wrong!'
                });
            } else {
                cogoToast.info('Landlord iban validated!', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                });
            }
        } catch(e) {
            console.log(e)
            let errorMessage = typeof e === "object" ? e.message || e.error : "Something went wrong"
            cogoToast.info(errorMessage, {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            });
        }
    }
    const handleValidateIban = (landlord, bankInfo) => {
        if(!bankInfo?.iban || bankInfo?.valid) return;
        setModal({
            title: landlord.name + "'s new iban",
            size: "lg",
            message: "Validate new iban: " + bankInfo.iban,
            onSubmit: validateIban(landlord, bankInfo),
        })
    }

    const landlordPhoto = landlord?.photo || defaultAvatar;

    const handlePhotoVerification = () => {
        if(!landlord?.photo || landlord?.photo_verified) return;
        setModal({
            title: landlord.name+"'s profile photo",
            size: "sm",
            message: <div>Do you want to verify {landlord.name}'s photo?</div>,
            onSubmit: async () => {
                let response = await landlordService.verifyPhoto(landlord_id);
                setModal(m => ({...m, hide: true}));
                if(response.error) {
                    cogoToast.error(response.error, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                } else {
                    setLandlord(prevLandlord => {
                        return {
                            ...prevLandlord,
                            "photo_verified": true
                        }
                    });
                    cogoToast.info('Landlord photo verified!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    });
                }
            },
        })
    }

    const LoadingDots = () => {
        return (
            <div className="dot-opacity-loader" style={{"width": "100%", "height": "22px", "transform": "translate(7px, -26px)"}}>
                <span style={{"margin": "2px, 2px", "height": "5px", "width": "5px"}}/>
                <span style={{"margin": "2px, 2px", "height": "5px", "width": "5px"}}/>
                <span style={{"margin": "2px, 2px", "height": "5px", "width": "5px"}}/>
            </div>
        )
    }

    const [loadingRemovePenalty, setLoadingRemovePenalty] = useState(false);
    const handleRemovePenalty = async () => {
        setLoadingRemovePenalty(true);
        let response = await landlordService.removePenalty(landlord.id);
        if(response.error) {
            cogoToast.error(response.error, {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        } else {
            setLandlord(prevLandlord => {
                return {
                    ...prevLandlord,
                    "penalty": null
                }
            });
            cogoToast.info('Penalty removed successfully!', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            });
        }
        setLoadingRemovePenalty(false);
    }

    const handleChangePrePaidBookings = async () => {
        let prepaid_bookings = !landlord.prepaid_bookings;
        setLandlord(prevLandlord => {
            return {
                ...prevLandlord,
                "prepaid_bookings": !prevLandlord.prepaid_bookings
            }
        });
        await landlordService.editLandlord(landlord.id, {"prepaid_bookings": prepaid_bookings});
    }

    return (
        (landlord ?
                <div className={"LandlordInformation"}>
                    <PageWrapper title={"Landlord's Page"}
                                 breadcrumbs={[
                                     {url: "landlords", title: "Landlords"},
                                     {title: landlord?.name}
                                 ]}
                                 noCard setModal={setModal}>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="LandlordInformation_header row">
                                            <div className="col-4">
                                                {landlord.topLandlord && <span className="badge badge-secondary text-dark mr-3">Top Landlord</span>}
                                                {landlord.promotion?.endDate && moment(landlord.promotion.endDate).isAfter(moment()) && <span className="badge badge-warning text-dark">Has Promotion</span>}
                                                <div className={"LandlordInformation_topstats mt-3"}>
                                                    <div>
                                                        <p className="number text-dark mb-0">{landlord.activeListings || 0}</p>
                                                        <p className="label text-muted">Active Listings</p>
                                                    </div>
                                                    <div>
                                                        <p className="number text-dark mb-0">{landlord.answerRatio}%</p>
                                                        <p className="label text-muted">Response Rate</p>
                                                    </div>
                                                    <div>
                                                        {(confirmedR || confirmedR === 0) ?
                                                            <p className="number text-dark mb-0">{confirmedR}</p> :
                                                            <LoadingDots/>}
                                                        <p className="label text-muted">Confirmed Bookings</p>
                                                    </div>
                                                    <div>
                                                        {(acceptedR || acceptedR === 0) ?
                                                            <p className="number text-dark mb-0">{acceptedR}</p>:
                                                            <LoadingDots/>}
                                                        <p className="label text-muted">Accepted Bookings</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div style={{backgroundImage: 'url(' + landlordPhoto + ')'}}
                                                     className="rounded-circle avatar">
                                                    <i className={"mdi mdi-check-circle " + (landlord?.photo && !landlord.photo_verified ? 'clickable' : '')} onClick={handlePhotoVerification} style={{color: landlord?.photo_verified ? "#2DC928" : "#6F6F6F"}}/>
                                                </div>
                                                <div className="LandlordInformation_header-info mb-2">
                                                    <h3 className="title text-capitalize">{landlord.name}</h3>
                                                    <p className="mb-0 d-flex align-items-center no-wrap">
                                                        <i className="mdi mdi-email-outline mr-2"/>
                                                        {landlord.email}
                                                    </p>
                                                    <p className={"d-flex align-items-center no-wrap"}>
                                                        <i className="mdi mdi-phone text-info mr-2"/>
                                                        {(landlord.dialCode ? landlord.dialCode + " " : "") + landlord.phone}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex flex-column p-0">
                                                <div className={"d-flex flex-1 justify-content-end"}>
                                                    <button className="btn btn-outline-primary btn-true-sm mr-2" onClick={editLandlordModal}>Edit Landlord</button>
                                                    <button className="btn btn-primary btn-true-sm" onClick={impersonateLandlord}>Impersonate</button>
                                                </div>
                                                <div className={"d-flex flex-1 justify-content-end mt-2"}>
                                                    <button className={"btn btn-secondary btn-true-sm" + (loadingSheetsIntegration ? " btn-loading" : "")}
                                                            disabled={loadingSheetsIntegration}
                                                            onClick={sheetsIntegration}>
                                                        Sheets Integration
                                                    </button>
                                                </div>
                                                <div className={"d-flex flex-1 justify-content-end mt-2"}>
                                                    <button className={"btn btn-outline-primary btn-true-sm"} onClick={advancedEditListings}>
                                                        Advanced Edit Listings
                                                    </button>
                                                </div>
                                                <div className={"d-flex flex-1 justify-content-end mt-2"}>
                                                    <Form.Group className={"check_box"}>
                                                        <label htmlFor="prePaidBookings">Pre-Paid Bookings Only</label>
                                                        <Form.Control type="checkbox" className="form-control" id="prePaidBookings"
                                                                      name="topLandlord" defaultChecked={!!landlord.prepaid_bookings}
                                                                      onClick={() => {
                                                                          handleChangePrePaidBookings()
                                                                      }}/>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"LandlordInformation_body row"}>
                                            <div className={"col-12 card"}>
                                                <div className={"card-body"}>
                                                    <p className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-cash icon-md-1 mr-2 text-muted"/>
                                                        {landlord?.multipleBankInfo?.find(info => info.default)?.iban || "N/A"}
                                                    </p>
                                                    <p className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-domain icon-md-1 mr-2 text-muted"/>
                                                        {landlord?.multipleFiscalInfo?.find(info => info.default)?.fiscalName || "N/A"}
                                                    </p>
                                                    <p className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-office-building icon-md-1 mr-2 text-muted"/>
                                                        {landlord?.address || "N/A"}
                                                    </p>
                                                    <p className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-file-document-edit-outline icon-md-1 mr-2 text-muted"/>
                                                        {landlord?.multipleFiscalInfo?.find(info => info.default)?.vat || "N/A"}
                                                    </p>
                                                    <span className="align-items-center d-flex mb-0" style={{fontSize: "0.875rem"}}>
                                                        <i className="mdi mdi-file-document-edit-outline icon-md-1 mr-2 text-muted"/>
                                                        Commission:&nbsp;
                                                        <span style={{textDecoration: landlord.promotion?.commission && moment(landlord.promotion.endDate).isAfter(moment()) ? "line-through" : ""}}>{landlord.noCommission ? "0%" : ((landlord.commission + "%") || "To be defined")}</span>
                                                        <span className={"ml-1"}>{landlord.noCommission ? "" : (landlord.firstRentCommission ? "- Over first rent" : "- Over contract of stay")}</span>
                                                        <span>{landlord.promotion?.commission && moment(landlord.promotion.endDate).isAfter(moment()) ?
                                                            <><b className={"ml-1"}>Promo: </b>{landlord.promotion.commission + "% until " + moment(landlord.promotion.endDate).format("DD/MM/YYYY")}</> : ""}</span>
                                                        {landlord.penalty ? <span className={"ml-1"} style={{color:"red"}}>(Penalty 2%, next {landlord.penalty} bookings)</span> : ""}
                                                        {(user?.permissions?.includes("landlords_remove_penalty") && landlord.penalty) ?
                                                            <button
                                                                className={"btn btn-true-sm btn-outline-danger ml-1" + (loadingRemovePenalty ? " btn-loading" : "")}
                                                                disabled={loadingRemovePenalty}
                                                                onClick={handleRemovePenalty}>Remove Penalty</button>
                                                        : ""}
                                                    </span>
                                                    <p className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-note-text icon-md-1 mr-2 text-muted"/>
                                                        Notes: {landlord.notes || "No notes recorded"}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </PageWrapper>
                    <div className="LandlordInformation_tabs row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <Tabs mountOnEnter defaultActiveKey="home" id="uncontrolled-tab-example">
                                        <Tab eventKey="properties" title="Listings" className="test-tab">
                                            <Properties landlord={landlord} overrideFetch={getLandlordProperties(landlord_id)} inline cardTableStyles={"mt-6"}/>
                                        </Tab>
                                        <Tab eventKey="reservations" title="Reservations">
                                            <Reservations overrideFetch={getLandlordReservations(landlord_id)} inline cardTableStyles={"mt-6"}/>
                                        </Tab>
                                        <Tab eventKey="visits" title="Visits">
                                            <Visits overrideFetch={getLandlordVisits(landlord_id)} inline cardTableStyles={"mt-6"}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {error ? <div style={{display: "flex", justifyContent:"center"}}>Something went wrong</div> : <Spinner inline/>}
                            </div>
                        </div>
                    </div>
                </div>
        )
    )
}

export default LandlordInformation;
