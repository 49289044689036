import {get, patch, post} from "./index.js";
import { useHistory } from "react-router-dom";
import React, {createContext, useContext, useEffect, useState} from "react";
import Spinner from "../app/shared/Spinner";

const NAMESPACE = "/backoffice/auth";

const authContext = createContext();

const useAuth = () => {
    return useContext(authContext);
}

const useProvideAuth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        (async ()=> {
            let ret = await loadUser();
            setLoading(false);
            if(ret && history.location.pathname.startsWith("/login")) {
                if(history.location.search.includes("redirect=")) {
                    history.push(decodeURIComponent(history.location.search.split("redirect=")[1]));
                } else {
                    history.push("/dashboard");
                }
            }
        })();
    }, []);

    const loadUser = async () => {
        if(user) return user;
        try {
            let _user = await get(NAMESPACE + "/me");
            setUser(_user);
            return _user;
        } catch (err) {
            return null;
        }
    }

    const login = async (username, password) => {
        let res = await post(NAMESPACE + "/login", {username, password});
        setUser(res.user);
        return res.user;
    }

    const logout = async () => {
        await get(NAMESPACE + "/logout")
        history.push("/login");
        setUser(null);
    }

    return {user, login, logout, loading};
}


const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {auth.loading ? <Spinner/> : children}
        </authContext.Provider>
    );
}

const impersonate = async (user_type, id) => {
    return await post("/backoffice/impersonateToken", {user_type, id});
}

export {ProvideAuth, useAuth};
export const authService = {
    impersonate
}
